import React from "react";

const Progress = props => {
  const steps = [
    "Choix plateforme",
    "Choix design",
    "Delai de livraison",
    "Votre adresse E-Mail",
    "Contact",
    "Votre devis"
  ];

  return (
    <>
      <div className="columns nav-768">
        {steps.map((stepTitle, i) => {
          let className = "column form-progress__step";
          className = props.stepNumber === i ? className + " active" : className;
          return (
            <div key={i} className={className}>
              {stepTitle}
            </div>
          );
        })}
      </div>

      <>
        <div className="columns nav-767">
          {steps.map((stepTitle, i) => {
            let className = "column form-progress__step";
            className = props.stepNumber === i ? className + " active" : className;
            return <div key={i} className={className} />;
          })}
        </div>
        <div className="form-progress__step-title_mobile">{steps[props.stepNumber]}</div>
      </>
    </>
  );
};

export default Progress;
