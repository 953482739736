import React from "react";

class Page extends React.Component {
  setChoice(datakey, choice) {
    let data = {};
    data[datakey] = choice;
    this.props.onStepUpdate(data);
    this.props.onNextStep();
  }

  render() {
    return (
      <div className="page-content__area">
        <h3>3. Quand aimeriez-vous recevoir votre site</h3>

        <div className="small-text">Un seul choix possible</div>

        <div className="page-content__choices-area">
          <span
            className="button choice-button"
            onClick={() => {
              this.setChoice("when", "Under a month");
            }}
          >
            Sous un mois
          </span>
          <span
            className="button choice-button"
            onClick={() => {
              this.setChoice("when", "Flexible deadline");
            }}
          >
            A definir
          </span>
        </div>
      </div>
    );
  }
}

export default Page;
