import React from "react";

const MastHead = () => (
  <h1 className="logo">
    <img
      src="/img/Logo_White_Transparent-1.png"
      alt="Bizness Move Digital Agency"
    />
  </h1>
);

export default MastHead;
