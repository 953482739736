import React from 'react'

import PricingTable from '../../components/PricingTable'
import FAQ from '../../components/FAQ'

const onCommanderClick = props => {
  // props.onNextStep();
  window.location = 'https://biznessmove.as.me/'
  return null
}

class Page extends React.Component {
  render() {
    const { platform, when, design } = this.props
    const { step, ...formData } = this.props
    const tableProps = { platform, when, design }

    return (
      <div className="page-content__area auto-width">
        <h3>Devis détaillé</h3>

        <div className="page-content__form-results-area">
          <PricingTable {...tableProps} />
          <CTA {...formData} />
          <FAQ />
          <CTA {...formData} />
        </div>
      </div>
    )
  }
}

const CTA = props => (
  <div className="cta-submit__wrapper">
    <span
      className="button choice-button cta-button"
      onClick={() => {
        onCommanderClick(props)
      }}
    >
      COMMANDER
    </span>
  </div>
)

export default Page
