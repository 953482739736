import React from "react";

class Page extends React.Component {
  setChoice(datakey, choice) {
    let data = {};
    data[datakey] = choice;
    this.props.onStepUpdate(data);
    this.props.onNextStep();
  }

  render() {
    return (
      <div className="page-content__area">
        <h3>1. Quelle plateforme preferez-vous?</h3>

        <div className="small-text">Un seul choix possible</div>

        <div className="page-content__choices-area">
          <span
            className="button choice-button"
            onClick={() => {
              this.setChoice("platform", "Help me decide");
            }}
          >
            Ja vous laisse choisir pour moi
          </span>
          <span
            className="button choice-button"
            onClick={() => {
              this.setChoice("platform", "Shopify");
            }}
          >
            Shopify
          </span>
          <span
            className="button choice-button"
            onClick={() => {
              this.setChoice("platform", "Prestashop");
            }}
          >
            Prestashop
          </span>
          <span
            className="button choice-button"
            onClick={() => {
              this.setChoice("platform", "WooCommerce");
            }}
          >
            WooCommerce
          </span>
          <span
            className="button choice-button"
            onClick={() => {
              this.setChoice("platform", "Magento");
            }}
          >
            Magento
          </span>
        </div>
      </div>
    );
  }
}

export default Page;
