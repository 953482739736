/* global history */

import React from "react";
import "./App.css";
import "./mobile.css";

import MastHead from "./components/MastHead";
import ProgressBar from "./components/Progress";
import Footer from "./components/Footer";

// Pages
import Page1 from "./pages/Page1";
import Page2 from "./pages/Page2";
import Page3 from "./pages/Page3";
import Page4 from "./pages/Page4";
import Page5 from "./pages/Page5";
import Page6 from "./pages/Page6";
import Page7 from "./pages/Page7";

const pageComponents = {
  page1: Page1,
  page2: Page2,
  page3: Page3,
  page4: Page4,
  page5: Page5,
  page6: Page6,
  page7: Page7
};

class App extends React.Component {
  // App State
  state = {
    step: 0,

    // form fields
    platform: null,
    design: null,
    when: null,
    email: null,
    title: "Mr.",
    first_name: null,
    last_name: null,
    phone: null,
    company_name: null
  };

  constructor() {
    super();
    this.updateStateData = this.updateStateData.bind(this);
  }

  componentDidMount() {
    /* Fake data for testing */
    // this.setState({
    //   company_name: "AAA Company",
    //   design: "Custom Design",
    //   email: "aaa@bbb.com",
    //   first_name: "AAA",
    //   last_name: "BBB",
    //   phone: "+61 5655858585",
    //   platform: "Shopify",
    //   step: 6,
    //   title: "Mr.",
    //   when: "Flexible deadline"
    // });
  }

  goToStep(stepNumber) {
    this.setState({
      step: stepNumber
    });

    if (process.browser) {
      if (stepNumber === 0) history.replaceState({}, document.title, "/?step=choixPtf"); // eslint-disable-line
      if (stepNumber === 1) history.replaceState({}, document.title, "/?step=choixDesign"); // eslint-disable-line
      if (stepNumber === 2) history.replaceState({}, document.title, "/?step=DelaiLivraison"); // eslint-disable-line
      if (stepNumber === 3) history.replaceState({}, document.title, "/?step=Email"); // eslint-disable-line
      if (stepNumber === 4) history.replaceState({}, document.title, "/?step=Coordonnees"); // eslint-disable-line
      if (stepNumber === 5) history.replaceState({}, document.title, "/?step=Devis"); // eslint-disable-line
    }
  }

  updateStateData(data) {
    this.setState(data);
  }

  render() {
    const currentStep = this.state.step;
    const nextStep = currentStep + 1;
    const PageComponentTag = pageComponents["page" + (currentStep + 1)];

    return (
      <>
        {/* Form pages */}
        {currentStep < 6 && (
          <>
            <header>
              <MastHead />
            </header>

            <div id="app">
              <div className="masthead">
                <h2>Devis création site e-commerce</h2>
              </div>

              <div className="form-progress__wrap">
                <ProgressBar stepNumber={currentStep} />
                <PageComponentTag
                  nextStep={nextStep}
                  onNextStep={() => this.goToStep(nextStep)}
                  onStepUpdate={this.updateStateData}
                  {...this.state}
                />
              </div>
            </div>

            <Footer />
          </>
        )}

        {/* Checkout Page */}
        {currentStep === 6 && (
          <PageComponentTag
            nextStep={nextStep}
            onNextStep={() => this.goToStep(nextStep)}
            onStepUpdate={this.updateStateData}
            {...this.state}
          />
        )}
      </>
    );
  }
}

export default App;
