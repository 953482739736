import React from "react";

function sendData(data) {
  fetch("/submit.php", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
  return true;
}

class Page extends React.Component {
  constructor() {
    super();
    this.nameRef = React.createRef();
    this.lastnameRef = React.createRef();
    this.phoneRef = React.createRef();
    this.companyRef = React.createRef();
  }

  setChoice(datakey, choice) {
    let data = {};
    data[datakey] = choice;
    this.props.onStepUpdate(data);
  }

  checkRefs() {
    let goodToGo = true;

    [this.nameRef, this.lastnameRef, this.phoneRef, this.companyRef].forEach(ref => {
      const el = ref.current;
      if (el.value.trim().length > 1) {
        el.classList.remove("input-error");
      } else {
        goodToGo = false;
        el.classList.add("input-error");
      }
    });

    return goodToGo;
  }

  goNext() {
    this.checkRefs() &&
      sendData({
        email: this.props.email,
        first_name: this.nameRef.current.value,
        last_name: this.lastnameRef.current.value,
        phone: this.phoneRef.current.value,
        company_name: this.companyRef.current.value
      }) &&
      this.props.onNextStep();
  }

  render() {
    return (
      <div className="page-content__area">
        <h3>5. Vos coordonnees</h3>

        <div className="page-content__choices-area">
          <div className="columns">
            <div className="column">Titre</div>
            <div className="column has-text-left">
              <label
                className="radio"
                onClick={newValue => {
                  this.setChoice("title", "Mr");
                }}
              >
                <input type="radio" name="answer" defaultChecked />M<span className="selection_visual" />
              </label>
              <label
                className="radio"
                onClick={newValue => {
                  this.setChoice("title", "Ms");
                }}
              >
                <input type="radio" name="answer" />
                MMe
                <span className="selection_visual" />
              </label>
            </div>
          </div>

          {/* this.setChoice(props.choiceLabel, e.target.value); */}

          <InputField
            label="Prenom"
            placeholder="John"
            icon="fa-user"
            ref={this.nameRef}
            onChange={newValue => {
              this.setChoice("first_name", newValue);
            }}
          />
          <InputField
            label="Nom"
            placeholder="Doe"
            icon="fa-user"
            ref={this.lastnameRef}
            onChange={newValue => {
              this.setChoice("last_name", newValue);
            }}
          />
          <InputField
            label="Telephone"
            placeholder="+33 (06) 2312 4554"
            icon="fa-phone"
            ref={this.phoneRef}
            onChange={newValue => {
              this.setChoice("phone", newValue);
            }}
          />
          <InputField
            label="Societe"
            placeholder="Joe's Company"
            icon="fa-building"
            ref={this.companyRef}
            onChange={newValue => {
              this.setChoice("company_name", newValue);
            }}
          />
        </div>

        <div className="columns">
          <div className="column">
            <span onClick={() => this.goNext()} className="button choice-button">
              Continuer
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const InputField = React.forwardRef((props, ref) => {
  const handleChange = e => props.onChange(e.target.value);

  return (
    <div className="columns">
      <div className="column">{props.label}</div>
      <div className="column">
        <div className="field">
          <p className="control has-icons-left">
            <input
              ref={ref}
              className="input"
              type="text"
              placeholder={props.placeholder}
              onChange={handleChange}
            />
            <span className="icon is-small is-left">
              <i className={`fas ${props.icon}`} />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
});

export default Page;
