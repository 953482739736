import React from "react";

const Footer = (props) => {
  return (
    <footer>
      <div className="section">
        <div className="app_wrap">
          <div className="columns">
            <div className="column">
              <h4>Exploitation facile</h4>
              <div>
                Votre site vous sera livré avec toutes les configurations déjà faites :
                <ul>
                  <li>Configuration des paiements en ligne</li>
                  <li>Configuration des options de livraison</li>
                  <li>Site sécurisé (très important)</li>
                  <li>Gestion automatique de plusieurs devises</li>
                  <li>Configuration des différents taux de TVA</li>
                </ul>
              </div>
            </div>
            <div className="column">
              <h4>Service Après-Vente</h4>
              <div>
                Après la livraison nous vous accompagnons dans la prise en main et l’exploitation de votre nouveau site
                e-commerce. Vous avez ainsi l’assurance de rentabiliser rapidement votre site.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-links columns has-text-centered">
        <div className="column">
          <p>
            <a
              className="iubenda-nostyle no-brand iubenda-embed iub-legal-only"
              style={{ color: "#fff" }}
              title="Privacy Policy "
              href="https://www.iubenda.com/privacy-policy/49208598"
            >
              POLITIQUE DE CONFIDENTIALITE
            </a>
            {/* <script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script> */}
          </p>
        </div>
        <div className="column">
          <a href="https://www.biznessmove.fr/contact/" target="_blank" rel="noopener noreferrer">
            CONTACT
          </a>
        </div>
        <div className="column">
          <p>
            <a
              className="iubenda-nostyle no-brand iubenda-embed"
              style={{ color: "#fff" }}
              title="Cookie Policy "
              href="https://www.iubenda.com/privacy-policy/49208598/cookie-policy"
            >
              POLITIQUE DE GESTION DES COOKIES
            </a>
            {/* <script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script> */}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
