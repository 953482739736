import React from "react";

function sendData(data) {
  fetch("/submit.php", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
  return true;
}

class Page extends React.Component {
  email = null;

  constructor() {
    super();
    this.emailRef = React.createRef();
  }

  setChoice(datakey, choice) {
    let data = {};
    data[datakey] = choice;
    this.props.onStepUpdate(data);
  }

  checkRefs() {
    const emailField = this.emailRef.current;
    if (emailField.value.trim().length > 1) {
      emailField.classList.remove("input-error");
      this.setChoice("email", emailField.value);
      this.email = emailField.value;
      return true;
    } else {
      this.email = null;
      emailField.classList.add("input-error");
      return false;
    }
  }

  goNext() {
    this.checkRefs() &&
      sendData({
        email: this.email,
        platform: this.props.platform,
        when: this.props.when,
        design: this.props.design
      }) &&
      this.props.onNextStep();
  }

  render() {
    return (
      <div className="page-content__area">
        <h3>4. A quelle adresse mail devons-nous vous envoyer le devis?</h3>

        <div className="page-content__choices-area">
          <div className="columns">
            <div className="column">Veuillez indiquer votre adresse mail ici</div>
            <div className="column">
              <div className="field">
                <p className="control has-icons-left">
                  <input className="input" type="text" placeholder="johndoe@gmail.com" ref={this.emailRef} />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <span onClick={() => this.goNext()} className="button choice-button">
              Continuer
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
