import React from "react";

class Page extends React.Component {
  render() {
    return (
      <>
        <header className="checkout-header section">
          <div className="checkout--container">
            <div className="columns">
              <column>
                <img src="/img/Logo_White_Transparent-1.png" alt="Bizness Move Digital Agency" />
              </column>
              <div className="column has-text-right">CALL US: 1 (888) 415-0615</div>
            </div>
          </div>
        </header>

        <div className="section">
          <div className="checkout--container">
            <div className="columns is-5 is-variable">
              <div className="column is-three-fifths">
                <form action="">
                  <h3 className="is-size-4">Contact Information</h3>
                  <InputRow fieldname="first_name" fieldvalue={this.props.first_name} fieldtitle="First Name*" />
                  <InputRow fieldname="last_name" fieldvalue={this.props.last_name} fieldtitle="Last Name*" />
                  <InputRow fieldname="email" fieldvalue={this.props.email} fieldtitle="Email*" />
                  <InputRow fieldname="phone" fieldvalue={this.props.phone} fieldtitle="Phone*" />

                  <h3 className="is-size-4">Billing Information</h3>
                  <InputRow fieldname="street_address_1" fieldvalue="" fieldtitle="Street Address 1 *" />
                  <InputRow fieldname="street_address_2" fieldvalue="" fieldtitle="Street Address 2" />
                  <InputRow fieldname="city" fieldvalue="" fieldtitle="City *" />
                  <InputRow fieldname="state" fieldvalue="" fieldtitle="State *" />
                  <InputRow fieldname="postal_Code" fieldvalue="" fieldtitle="Postal Code *" />
                  <InputRow fieldname="country" fieldvalue="" fieldtitle="Country *" />

                  <h3 className="is-size-4">Credit Card Information</h3>
                  <InputRow fieldname="cc_type" fieldvalue="" fieldtitle="Card Type *" />
                  <InputRow fieldname="cc_num" fieldvalue="" fieldtitle="Card Number *" />
                  <InputRow fieldname="cc_exp_month" fieldvalue="" fieldtitle="Expiration Month *" />
                  <InputRow fieldname="cc_exp_year" fieldvalue="" fieldtitle="Expiration Year *" />
                  <InputRow fieldname="cc_cvc" fieldvalue="" fieldtitle="CVC *" />

                  <div>
                    <button class="button checkout--button large is-link" type="submit">Pay Now</button>
                  </div>
                </form>
              </div>

              <div className="column">
                <div className="checkout-usp--container">
                  <h3 className="is-size-4">AMAZING SELLING MACHINE</h3>
                  <ul className="checkout-usp--bullets__container">
                    <li>
                      <strong>✔ The Brand New 8-Module Online Web Class -</strong> Start, launch, and scale on Amazon
                      FAST with this step-by-step program you can watch anytime right from your own home!
                    </li>
                    <li>
                      <strong>✔ Access the Private and Exclusive Community -</strong> Get insider-only strategies and
                      meet top Amazon sellers in the private community. Lifetime access included with ASM!
                    </li>
                    <li>
                      <strong>✔ Personal Support from the ASM Mentors -</strong> Build your business faster and easier
                      no matter your experience level with this team of highly successful Amazon sellers ready to help
                      you!
                    </li>
                    <li>
                      <strong>✔ The Private Resource Vault -</strong> Access our own personal contacts and resources and
                      get private discounts with this special component of ASM valued at over ,000 alone!
                    </li>
                    <li>
                      <strong>✔ The 30-Day Money-Back Guarantee -</strong> Get a 100% refund anytime in the first 30
                      days if ASM isn't right for you!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const InputRow = props => (
  <div className="checkout--form-row">
    <label htmlFor={props.fieldname}>{props.fieldtitle}</label>
    <input type="text" name="first_name" value={props.fieldvalue} />
  </div>
);

export default Page;
