import React from "react";

class Page extends React.Component {
  setChoice(datakey, choice) {
    let data = {};
    data[datakey] = choice;
    this.props.onStepUpdate(data);
    this.props.onNextStep();
  }

  render() {
    return (
      <div className="page-content__area">
        <h3>2. Quel design preferez-vous?</h3>

        <div className="small-text">Une seule selection possible</div>

        <div className="page-content__choices-area">
          <span
            className="button choice-button"
            onClick={() => {
              this.setChoice("design", "Choose from template");
            }}
          >
            Choisir parmi des modeles prefedinis
          </span>
          <span
            className="button choice-button"
            onClick={() => {
              this.setChoice("design", "Custom Design");
            }}
          >
            Design personalise
          </span>
        </div>
      </div>
    );
  }
}

export default Page;
