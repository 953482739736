import React from 'react'

class FAQ extends React.PureComponent {
  state = {
    q1: false,
    q2: false,
    q3: false
  }

  render() {
    return (
      <div className="faq-section has-text-left">
        <h3 className="has-text-centered">Questions Fréquentes</h3>

        <div className="faq-item">
          <div
            onClick={() => {
              this.setState({ q1: !this.state.q1 })
            }}
            className="faq-question columns is-mobile"
          >
            <div className="column faq-icon is-one-quarter">
              <i className="fas fa-plus-square" />
            </div>
            <div className="column faq-question">
              Que se passe-t-il- aprês ma commande?
            </div>
          </div>

          {this.state.q1 && (
            <div className="faq-answer columns is-centered">
              <div className="faq-answer-text column is-half">
                <p>
                  Après votre commande nous prendrons immédiatement contact avec
                  vous pour recueillir toutes les informations concernant votre
                  projet.
                </p>
                <p>
                  Ensuite nous allons vous proposer un planning de réalisation
                  et commencerons le développement de votre site selon ce
                  planning.
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="faq-item">
          <div
            onClick={() => {
              this.setState({ q2: !this.state.q2 })
            }}
            className="faq-question columns is-mobile"
          >
            <div className="column faq-icon is-one-quarter">
              <i className="fas fa-plus-square" />
            </div>
            <div className="column faq-question">
              Quels sont vos délais de livraison?
            </div>
          </div>
          {this.state.q2 && (
            <div className="faq-answer columns is-centered">
              <div className="faq-answer-text column is-half">
                <p>
                  Le délai de livraison dépend de la complexité du projet, mais
                  en moyenne nous livrons en moins de 30 jours.
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="faq-item">
          <div
            onClick={() => {
              this.setState({ q3: !this.state.q3 })
            }}
            className="faq-question columns is-mobile"
          >
            <div className="column faq-icon is-one-quarter">
              <i className="fas fa-plus-square" />
            </div>
            <div className="column faq-question">J’ai une autre question</div>
          </div>

          {this.state.q3 && (
            <div className="faq-answer columns is-centered">
              <div className="faq-answer-text column is-half">
                <p>
                  Vous pouvez nous contacter par mail à :
                  support@biznessmove.com
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default FAQ
