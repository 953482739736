import React from "react";

class PricingTable extends React.PureComponent {
  constructor() {
    super();
    this.tableProps = {};
  }

  setCopyAndPricing() {
    const { platform, when, design } = this.props;
    this.tableProps = { platform, when, design };

    switch (platform) {
      case "Help me decide":
        this.tableProps.platform = "Ja vous laisse choisir pour moi";
        this.tableProps.platformPrice = 399;
        break;
      case "Shopify":
        this.tableProps.platformPrice = 599;
        break;
      case "Prestashop":
        this.tableProps.platformPrice = 1950;
        break;
      case "Woocommerce":
        this.tableProps.platformPrice = 399;
        break;
      case "Magento":
        this.tableProps.platformPrice = 2950;
        break;
      default:
        this.tableProps.platformPrice = 399;
        break;
    }

    switch (design) {
      case "Choose from template":
        this.tableProps.platform = "Ja vous laisse choisir pour moi";
        this.tableProps.designPrice = 399;
        break;

      case "Custom Design":
        this.tableProps.design = "Design personalise";
        this.tableProps.designPrice = 850;
        break;

      default:
        this.tableProps.platform = "Ja vous laisse choisir pour moi";
        this.tableProps.designPrice = 399;
        break;
    }

    switch (when) {
      case "Under a month":
        this.tableProps.when = "Sous un mois";
        this.tableProps.whenPrice = 100;
        break;

      case "Flexible deadline":
        this.tableProps.when = "A definir";
        this.tableProps.whenPrice = 100;
        break;

      default:
        this.tableProps.when = "Sous un mois";
        this.tableProps.whenPrice = 100;
        break;
    }

    this.tableProps.totalPrice =
      this.tableProps.platformPrice + this.tableProps.designPrice + this.tableProps.whenPrice;
  }

  render() {
    this.setCopyAndPricing();
    return (
      <>
        <DesktopPricingTable {...this.tableProps} />
        <MobilePricingTable {...this.tableProps} />
      </>
    );
  }
}

class MobilePricingTable extends React.PureComponent {
  render() {
    return (
      <div className="userchoices is-hidden-tablet">
        <UserChoiceCard
          questionText="1. Quelle plateforme préférez-vous?"
          userSelectionText={this.props.platform}
          price={this.props.platformPrice}
        />
        <UserChoiceCard
          questionText="2. Quel design preferez-vous?"
          userSelectionText={this.props.design}
          price={this.props.designPrice}
        />
        <UserChoiceCard
          questionText="3. Quand aimeriez-vous recevoir votre site?"
          userSelectionText={this.props.when}
          price={this.props.whenPrice}
        />

        <div className="pricing--total_section">
          <div className="pricing--total_text">
            TOTAL: <span>{`€${this.props.totalPrice},00`}</span>
          </div>
        </div>

        <div className="pricing--footer_text has-text-right">
          N.B: Ce devis n'est donné qu'à titre indicatif. <br />
          Le devis final dépendra des <br />
          spécificités de votre projet.
        </div>
      </div>
    );
  }
}

class UserChoiceCard extends React.PureComponent {
  render() {
    const { questionText, userSelectionText, price } = this.props;
    return (
      <div className="userchoice--card">
        <div className="userchoice--card__label">Option</div>
        <div className="userchoice--card__choice-text">{questionText}</div>
        <div className="userchoice--card__label">Votre choix</div>
        <div className="userchoice--card__choice-text">{userSelectionText}</div>
        <div className="userchoice--card__label">Quantité</div>
        <div className="userchoice--card__choice-text">1</div>
        <div className="userchoice--card__label">Prix HT</div>
        <div className="userchoice--card__choice-text">{`€${price},00`}</div>
      </div>
    );
  }
}

class DesktopPricingTable extends React.PureComponent {
  render() {
    return (
      <table className="page-content__results-table is-hidden-mobile">
        <thead>
          <tr>
            <th>Option</th>
            <th>Votre choix</th>
            <th>Quantité</th>
            <th>Prix HT</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1. Quelle plateforme préférez-vous?</td>
            <td>{this.props.platform}</td>
            <td>1</td>
            <td>{`€${this.props.platformPrice},00`}</td>
          </tr>
          <tr>
            <td>2. Quel design preferez-vous?</td>
            <td>{this.props.design}</td>
            <td>1</td>
            <td>{`€${this.props.designPrice},00`}</td>
          </tr>
          <tr>
            <td>3. Quand aimeriez-vous recevoir votre site?</td>
            <td>{this.props.when}</td>
            <td>1</td>
            <td>{`€${this.props.whenPrice},00`}</td>
          </tr>
          <tr className="total-row">
            <td colSpan="2">
              N.B: Ce devis n'est donné qu'à titre indicatif. <br />
              Le devis final dépendra des <br />
              spécificités de votre projet.
            </td>
            <td className="totalCol" colSpan="2">
              TOTAL: {`€${this.props.totalPrice},00`}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default PricingTable;
